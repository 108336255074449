<template>
  <v-card
    elevation="2"
    :class="{}"
    class="d-flex align-center justify-space-between flex-row py-2 pl-4 pr-5"
    style="gap: 1rem"
    :style="{
      width: fullWidth ? '100%' : 'auto',
    }"
    rounded="lg"
  >
    <div class="d-flex flex-column align-start" style="gap: 0.5rem">
      <h4>
        <i class="fad fa-folder-open mr-1 secondary--text"></i>
        <a
          small
          text
          target="_blank"
          class="py-0 px-1 fs-13px secondary--text"
          max-height="20px"
          :href="`/projects/${value.project.id}`"
          >{{ value.project.fullName }}
        </a>
      </h4>
      <h4 class="fs-13px">
        <i class="fad fa-file-signature mr-1" style="width: 16px"></i>
        <code class="fs-11px blue-grey lighten-5 mono-font mr-1">#{{ value.contract.id }}</code>
        {{ value.contract.name }}
      </h4>
      <h4 class="d-flex align-center">
        <i class="fad fa-calendar-lines mr-2 secondary--text" style="width: 16px"></i>
        <v-chip color="secondary" dark class="px-1" label x-small>
          <span class="fs-13px font-weight-medium">{{
            value.constructionPhase.startDate.substr(0, 7)
          }}</span>
        </v-chip>
        <i class="fad fa-circle fs-8px mx-2" style="line-height: 1"></i>
        <span>
          <construction-phase-type :type="value.constructionPhase.type"></construction-phase-type>
          {{ value.constructionPhase.name }}</span
        >
      </h4>
    </div>
    <div class="d-flex flex-row align-center" style="gap: 1rem">
      <div class="d-flex flex-column align-end text-right" style="gap: 1px">
        <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
          getLaborTypeObj(value.laborType).desc
        }}</b>
        <user-all-selector
          :users="users"
          required
          small
          hideLabel
          hideDetails
          readonly
          hideViewBtn
          responsive
          hideBorder
          :isUsersLoading="isUsersLoading"
          v-model="value.userId"
        ></user-all-selector>
        <span class="d-flex align-center" style="gap: 0.35rem">
          <span class="fs-12px font-weight-medium opacity-64">Hours:</span>
          <span
            class="fs-18px font-weight-medium mono-font"
            style="line-height: 1.7"
            :class="{
              'green--text': value.hours > 0,
              'pink--text': value.hours < 0,
            }"
          >
            {{ value.hours.toString().padStart(2, "0") }}
          </span>
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import enums from "../../../plugins/enums";
import ConstructionPhaseType from "../../Contracts/components/ConstructionPhaseType.vue";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";

export default {
  name: "phase-record-card",
  components: { ConstructionPhaseType, UserAllSelector },
  data() {
    return {
      enums,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    isUsersLoading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
  },
};
</script>

<style lang="scss"></style>
