<template>
  <div class="d-inline-flex" v-if="type != null && typeObj != null">
    <v-tooltip top z-index="999" nudge-top="-4px">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-chip
            class="equipment-installer px-2"
            :small="!small"
            :x-small="small"
            :color="typeObj.color || 'secondary'"
            :text-color="typeObj.textColor || 'white'"
          >
            <span class="font-weight-medium" style="line-height: 0">{{
              typeObj.text.toUpperCase()
            }}</span>
          </v-chip>
        </div>
      </template>
      <span>{{ typeObj.description }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";

export default {
  name: "construction-phase-type",
  data() {
    return {
      typeObj: null,
    };
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateStatusObj();
  },
  methods: {
    updateStatusObj() {
      if (this.type != null) {
        this.typeObj = this.getEnumMember(Enums.CONSTRUCTION_PHASE_TYPE, this.type);
      }
    },
  },
  watch: {
    type: {
      handler() {
        this.updateStatusObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
