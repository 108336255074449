import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "ConstructionPhaseRecords";
export default {
  getApprovedConstructionPhaseRecordsByRange(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "/GetApprovedConstructionPhaseRecordsByRange?" + qParams.toString());
  },
  getApprovedConstructionPhaseRecordsByProject(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(
      baseUrl + "/GetApprovedConstructionPhaseRecordsByProject?" + qParams.toString()
    );
  },
  getAvailableConstructionPhaseRecordsByRange(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(
      baseUrl + "/GetAvailableConstructionPhaseRecordsByRange?" + qParams.toString()
    );
  },
  getAvailableConstructionPhaseRecordsByProject(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(
      baseUrl + "/GetAvailableConstructionPhaseRecordsByProject?" + qParams.toString()
    );
  },
  getAvailableHoursSources(options) {
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/GetAvailableHoursSources?" + qParams.toString());
  },
  getConstructionPhaseRecordsForSpecifiedOffers(options) {
    const qParams = new URLSearchParams(options);
    return Api().post(baseUrl + "/GetConstructionPhaseRecordsForSpecifiedOffers", options);
  },
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  cloneConstructionPhase(id, entity) {
    return Api().post(baseUrl + `/${id}/Clone`, entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  unlink(id) {
    return Api().put(baseUrl + `/${id}/Unlink`);
  },
};
